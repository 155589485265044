import React from "react"
import {Link} from "gatsby"
import { Waypoint } from 'react-waypoint';

import {handleWaypointIn} from "../utilities/waypoint"
import Layout from "../components/layout/Layout"
import SEO from "../components/seo"
import ContactSection from "../components/contact/ContactSection"
import ChrisPulp from '../images/chris-pulp.gif'


const NotFoundPage = () => (
    <div className="error-404">
        <Layout>
            <SEO 
                title="Oops! Error: 404"
                description="This is the Opps I couldn't find what you were loking for page! Ecommerce Web Development | Woocommerce Ecommerce Web Development | Shopify Ecommerce Web Development" 
            />
            <section className="page-header">
                <Waypoint onEnter={() => handleWaypointIn('oops-text', 'slide-in-elliptic-right-fwd')}>         
                    <div id="oops-text">                    
                        <h1>OOPS! </h1> 
                    </div>    
                </Waypoint>                             
            </section> 
        
        
            <section className="about-me">
                <div className="about-me-wrapper">
                    <div className="columns-2 image-right" id="about-top">
                        <div className="column-1">
                            <div className="column-content left">
                                <Waypoint onEnter={() => handleWaypointIn('about-me-text')}>         
                                    <div id="about-me-text"> 
                                    <h2>Sorry I couldn't find the page you were looking for!</h2>

                                    <p>If you are interested in finding out more about me, and who could blame you, please visit <Link to="/about">ABOUT ME</Link>.</p>
                                    <p>If you want to know what my works looks like, please visit <Link to="/portfolio">PORTFOLIO</Link>.</p>
                                    <p>If you are interested in finding out more about Web Developement or Wordpress then please visit <Link to="/web-development">WEB DEVELOPMENT</Link>.</p>
                                    <p>If ecommerce, Shopify or Woocommerce interest you, then please visit <Link to="/ecommerce-web-development">ECOMMERCE WEB DEVELOPMENT</Link>.</p>
                                    <p>If you're interested in forming a partnership or outsourcing, then please visit <Link to="/partnerships">PARTNERSHIPS</Link>.</p>
                                    <p>If you have more questions then please visit <Link to="/frequently-asked-questions">FAQ</Link>.</p>
                                    <p>If you want to contact me then use the messenger icon or fill out the form below.</p>           
                                    </div>    
                                </Waypoint>
                            </div>
                        </div>
                        <div className="column-2 image-wrapper">
                            <Waypoint onEnter={() => handleWaypointIn('cp')}>         
                                <img src={ChrisPulp} alt="Chris Bartlett Full-Stack Web Developer Can't find what you're looking for" id="cp" loading="lazy" />
                            </Waypoint>  
                        </div>
                    </div>
                </div>           
            </section>   



            <ContactSection/>  
        </Layout>
    </div>
)


export default NotFoundPage